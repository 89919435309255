<template>
  <div class="page">
    <van-nav-bar
      fixed
      title="关联辅件"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="search">
      <div class="search-condition">
        <div class="search-total">共{{ total }}条结果</div>
      </div>
    </div>
    <van-pull-refresh
      ref="vanList"
      v-model="refreshing"
      class="data-list"
      @refresh="pullGetAgain"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText || '没有更多了'"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div v-for="item in list" :key="item.id" class="data-item">
          <div class="data-container" @click="gotoEquipment(item.id)">
            <div class="data-row">
              <div class="data-title">
                {{ item.name }}
              </div>
              <div class="data-tip">
                <span class="four" :class="`level-${item.level}`">{{
                  item.levelName
                }}</span>
                <span class="three" :class="`type-${item.type}`">{{
                  item.typeName
                }}</span>
              </div>
              <div class="data-status">
                <img
                  v-if="item.equipmentStatus === '1'"
                  :src="require(`@/assets/images/equipment/status-on.png`)"
                />
                <img
                  v-if="item.equipmentStatus === '2'"
                  :src="require(`@/assets/images/equipment/status-off.png`)"
                />
                <span>{{ item.equipmentStatusName }}</span>
              </div>
            </div>
            <div class="data-row">
              <div class="data-info">
                <span v-if="item.machineCode">{{ item.machineCode }}</span>
                <span v-if="item.machineNo">{{ item.machineNo }}</span>
                <span>{{ item.safetyCategoryName }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getEquipmentLinkAccessory } from "./api";

export default {
  name: "EquipmentLinkAccessory",
  data() {
    return {
      total: 0,
      list: [],
      query: {
        page: 1,
        size: 10
      },
      refreshing: false,
      loading: false,
      finishedText: "",
      finished: false
    };
  },
  methods: {
    gotoEquipment(id) {
      this.$router.push({ name: "equipmentledger", params: { id } });
    },
    getList() {
      getEquipmentLinkAccessory({
        equipmentRecordId: this.$route.params.id,
        ...this.query
      }).then(res => {
        this.loading = false;
        if (res.list && res.list.length) {
          this.total = res.total;
          this.list = this.list.concat(res.list);
          this.query.page++;
        } else {
          if (!this.list.length) {
            this.finishedText = "没有更多了";
          } else {
            this.finishedText = "";
          }
          this.finished = true;
        }
      });
    },
    //下拉刷新
    pullGetAgain() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      this.getList();
    },
    scrollTo() {
      this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    },
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  overflow: hidden;

  .search {
    margin-top: 58px;
    padding-top: 4px;

    .search-condition {
      display: flex;
      justify-content: center;

      .search-total {
        height: 18px;
        line-height: 18px;
        color: #8c8f97;
        text-align: center;
        font-size: 12px;
        padding: 12px 0 0;
        font-weight: 400;
      }
    }
  }

  .data-list {
    .data-item {
      font-size: 14px;
      font-weight: 550;
      color: #2e2e4d;
      padding: 16px;
      border-bottom: 7px solid #eceef2;

      .data-container {
        overflow: hidden;

        > div {
          float: left;
          &:first-child {
            margin-right: 4px;
          }
        }

        .data-row {
          width: 100%;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .data-title {
          float: left;
          height: 16px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .data-status {
          float: right;
          > img {
            margin-right: 2px;
          }
        }

        .data-info {
          font-size: 12px;
          color: #a0a2a6;

          > span {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .data-tip {
    float: left;
    margin-left: 4px;
    font-size: 10px;
    height: 16px;

    > span {
      display: inline-block;
      margin-right: 4px;
      padding: 2px;
      border-radius: 2px;
      text-align: center;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .four {
      width: 48px;
    }

    .three {
      width: 42px;
    }

    .level-1 {
      color: #1676ff;
      background: rgba(22, 118, 255, 0.1);
    }

    .level-2 {
      color: #f49523;
      background: rgba(244, 149, 35, 0.1);
    }

    .level-3 {
      color: #fa5555;
      background: rgba(250, 85, 85, 0.1);
    }

    .type-1 {
      color: #1676ff;
      background: rgba(22, 118, 255, 0.1);
    }

    .type-2 {
      color: #0fb8c5;
      background: rgba(15, 184, 197, 0.1);
    }

    .type-3 {
      color: #67c23a;
      background: rgba(103, 194, 58, 0.1);
    }

    .type-4 {
      color: #67c23a;
      background: rgba(103, 194, 58, 0.1);
    }

    i {
      color: #aeb3c0;
    }
  }
}
</style>
